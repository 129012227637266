import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// import { Link } from 'gatsby';
// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemHeading,
//   AccordionItemButton,
//   AccordionItemPanel,
// } from 'react-accessible-accordion';
import axios from 'axios';
import cn from 'clsx';
// import isEmail from 'validator/lib/isEmail';
// import ContactUsContainer, {
//   ContactUsModal,
// } from 'components/ContactUs/ContactUs.styled';
import Layout from 'components/Layout';
import Meta from 'components/Meta';
// import { nanoid } from 'nanoid';

import {
  brandLogosBugTrack,
  customerNumber,
  getErrorMessage,
  pricingTableData,
} from '../utils/contentProvider';
import { signUp } from '../utils/contentProvider/function';
// import stripeCountries from '../utils/stripeCountries';
import useForm from '../hooks/useForm';
import { isValidEmail } from '../utils/validations';
// import OfferCoupon from '../components/OfferCoupon/OfferCoupon';
import 'styles/pricing.scss';

import NewFaq from '../components/NewFaq/NewFaq';
import { PricingTable } from '../components/PricingTable/PricingTable';
import { faqDataCommon } from '../utils/contentProvider/faqDataCommon';

const views = {
  individual: 0,
  businesses: 1,
  lifetime: 2,
};
const proPlan = {
  monthly: 8,
  yearly: 6,
};

const standardPlan = {
  monthly: 15,
  yearly: 13,
};
const teamPlan = {
  monthly: 30,
  yearly: 25,
};
const offerings = {
  free: [
    '',
    'Upto 5 Users',
    '1 project',
    'Unlimited Guests',
    'All Integrations',
  ],
  pro: [
    'All in Free',
    '1 admin + upto 50 users',
    'Unlimited Projects',
    'Unlimited Guests',
    '10 mins of Video Commenting',
    '50GB of Data Storage',
    'Dedicated Success Manager',
  ],
  team: [
    'All in Pro',
    'Admin users with project management abilities',
    'Centralised Project Management',
    'Dedicated Team Workspace',
    'SAML Support',
  ],
};

const enterpriseData = {
  content:
    'Streamline the process of website feedback with unlimited projects and collaborators, run ruttl on your custom domain and customise it in your brand colours with our enterprise plan.',
  subtext: 'Simplified website feedback process for your enterprise',
  product: 'Website Feedback Tool',
  btnId: 'wtfp',
};

const WebsiteFeedbackToolPricing = ({ location }) => {
  const [currentTab, setCurrentTab] = useState(views.businesses);
  const [teamDropdown, setTeamDropdown] = useState(false);
  const [teamMembers, setTeamMembers] = useState(5);
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [websitePlan, setWebsitePlan] = useState('yearly');

  const [fetchingLink /* setFetchingLink */] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [position, setPosition] = useState('');
  const [showDemoCallModal, setShowDemoCallModal] = useState(false);

  const toggleTab = (tab) => () => setCurrentTab(tab);

  const toggleTeamDropdown = () => setTeamDropdown((state) => !state);

  const updateTeamMembers = (e) => {
    setTeamMembers(Number(e.target.closest('button').dataset.size));
    setTeamDropdown(false);
  };

  const getProPlanValue = () => (websitePlan === 'yearly' ? '6' : '8');

  const getStandardPlanValue = () => (websitePlan === 'yearly' ? '13' : '15');

  const getTeamPlanValue = () => (websitePlan === 'yearly' ? '25' : '30');

  const togglePurchaseModal =
    (payload = false) =>
    // eslint-disable-next-line indent
    () =>
      // eslint-disable-next-line indent
      setShowPurchaseModal(payload);

  // const inputChangeHandler = (e) => {
  //   if (e.target.name === 'country') setUserCurrency(e.target.value);
  //   if (e.target.name === 'customer-email') setUserEmail(e.target.value);
  // };
  // const paySubmitHandler = (e) => {
  //   e.preventDefault();

  //   setFetchingLink(true);

  //   axios
  //     .post(
  //       `https://us-central1-rally-brucira.cloudfunctions.net/stripeCreateCheckoutSession`,
  //       {
  //         planID: `team-${teamMembers}`,
  //         planDescription: `Team-${teamMembers} plan`,
  //         cancelURL: 'https://ruttl.com/pricing/',
  //         successURL: 'https://web.ruttl.com/signup',
  //         email: userEmail,
  //         interval: currentTab === 0 ? 'month' : 'year',
  //         currency: stripeCountries
  //           .find(({ countryCode }) => countryCode === userCurrency)
  //           .currencyCode.toLowerCase(),
  //       },
  //     )
  //     .then((response) => {
  //       if (typeof response.data === 'string') window.location = response.data;
  //     })
  //     .catch(() => {
  //       setFetchingLink(false);
  //     });
  // };

  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', (e) => {
        e.preventDefault();

        document.querySelector(anchor.getAttribute('href')).scrollIntoView({
          behavior: 'smooth',
        });
      });
    });
  }, []);

  const formProps = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      company: '',
    },
    validations: {
      name: {
        required: true,
      },
      email: {
        required: true,
        rules: [
          {
            validator: (value) => isValidEmail(value),
            message: getErrorMessage('error', 'email'),
          },
        ],
      },
    },
    onSubmit: (payload, reset) => {
      (async () => {
        try {
          setLoading(true);

          await axios.post(
            'https://us-central1-rally-brucira.cloudfunctions.net/contact',
            {
              subject: 'Enterprise-Custom-Website-Feedback-Tool',
              email: payload.email,
              message: `${payload.name} has shown interest in ${teamMembers} members Custom Enterprise plan.`,
            },
          );

          // reset form
          reset();

          // setPosition(p); // set position in state

          setShowDemoCallModal(true); // open modal
        } catch (e) {
          // eslint-disable-next-line no-alert
          window.alert('Something went wrong, try again');
        } finally {
          setLoading(false);
        }
      })();
    },
  });

  const handleChange = (e) => {
    formProps.onChange(e.target.name, e.target.value);
  };
  return (
    <Layout
      location={location}
      hideTopBar
      productPage
      product="Website Feedback Tool">
      {({ toggleSignup }) => (
        <>
          <Meta
            title="Ruttl Pricing - Website Feedback Tool"
            description="Planning to use ruttl for your design projects? First project is free! If you need more, simply choose any pricing plan that suits your team size."
            url="https://ruttl.com/pricing/"
          />

          <main className="styled-pricing-main">
            <section className="pricing-hero grey-bg">
              <div className="container">
                <div className="pricing-hero-top center">
                  {/* <div className="flex flex-wrap justify-content-between align-items-center"> */}
                  <div className="  ">
                    <div>
                      <h1>Choose the Right Plan:</h1>
                      {/* <p className="pricing-detail">Pay monthly or annually</p> */}
                    </div>
                    <div className="pricing-toggle-container column gap-4 justify-content-center">
                      <div className="pricing-toggle-main">
                        <button
                          type="button"
                          className={
                            websitePlan === 'monthly' ? 'active-price' : ''
                          }
                          onClick={() => setWebsitePlan('monthly')}>
                          Monthly
                        </button>
                        <button
                          type="button"
                          className={
                            websitePlan === 'yearly' ? 'active-price' : ''
                          }
                          onClick={() => setWebsitePlan('yearly')}>
                          Yearly
                        </button>
                        {/* <button
                          type="button"
                          className={
                            currentTab === views.lifetime ? 'active-price' : ''
                          }
                          onClick={toggleTab(views.lifetime)}>
                          Lifetime
                        </button> */}
                      </div>
                      {/* <p
                        className={`pricing-toggle-text ${
                          currentTab === views.yearly
                            ? 'pricing-toggle-text-hide'
                            : ''
                        }`}>
                        Get
                        <span className="blue bold">
                          &nbsp;2 months free&nbsp;
                        </span>
                        with our Yearly plans!
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap justify-content-between hero-pricing-blocks">
                  <div className="plan-single">
                    <div className="plan-top">
                      <h4>Free</h4>
                      <p className="plan-amount">
                        <>$0</>
                      </p>
                      <span className="billing-text">
                        /month/user&nbsp;
                        {websitePlan === 'yearly' ? 'billed yearly' : ''}
                      </span>
                    </div>
                    <div className="plan-main">
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        id="free-get-started-wftp-hero"
                        className="button button-white blue-text">
                        Get Started
                      </button>
                      <ul className="offerings">
                        {offerings.free.map((offer) => (
                          <li>
                            {offer.trim() !== '' && (
                              <svg
                                width="12"
                                height="9"
                                viewBox="0 0 14 11"
                                fill="none">
                                <path
                                  d="m1 6.2 3.6 3.6L13 1.4"
                                  stroke="#6552FF"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                            <span>{offer}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="plan-single recommended-plan">
                    <div className="plan-top">
                      <h4 className="link-tag" data-value="Recommended">
                        Pro
                      </h4>

                      <p className="plan-amount">
                        <>
                          {websitePlan === 'yearly'
                            ? `$${proPlan.yearly}`
                            : `$${proPlan.monthly}`}
                        </>
                      </p>
                      <span className="billing-text">
                        /month/user&nbsp;
                        {websitePlan === 'yearly' ? 'billed yearly' : ''}
                      </span>
                    </div>
                    <div className="plan-main">
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        id="pro-get-started-wftp-hero"
                        className="button ">
                        Get Started
                      </button>
                      <ul className="offerings">
                        {offerings.pro.map((offer) => (
                          <li>
                            <svg
                              width="12"
                              height="9"
                              viewBox="0 0 14 11"
                              fill="none">
                              <path
                                d="m1 6.2 3.6 3.6L13 1.4"
                                stroke="#6552FF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <span>{offer}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="plan-single">
                    <div className="plan-top">
                      <h4>Business</h4>

                      <p className="plan-amount">{`$${getTeamPlanValue()}`}</p>
                      <span className="billing-text">
                        /month/user&nbsp;
                        {websitePlan === 'yearly' ? 'billed yearly' : ''}
                      </span>
                    </div>
                    <div className="plan-main">
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        id="team-get-started-wftp-hero"
                        className="button button-white blue-text">
                        Get Started
                      </button>
                      <ul className="offerings">
                        {offerings.team.map((offer) => (
                          <li>
                            <svg
                              width="12"
                              height="9"
                              viewBox="0 0 14 11"
                              fill="none">
                              <path
                                d="m1 6.2 3.6 3.6L13 1.4"
                                stroke="#6552FF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <span>{offer}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <a href="#pricing-table" className="button-transparent">
                  Explore all features
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7 1.16663V12.8333M12.8333 6.99996L7 12.8333L1.16666 6.99996"
                      stroke="#6552FF"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
              </div>
            </section>
            <section className="pricing-testimonial">
              <div className="container">
                <div className="center head">
                  <h2>
                    Loved by&nbsp;
                    {customerNumber}
                    &#43;&nbsp;Creators &amp; Businesses
                  </h2>
                </div>
                <div className="pricing-testimonial-box">
                  <div className="flex justify-content-between align-items-center">
                    <div className="pricing-testimonial-img hidden-mob">
                      <img
                        src="/assets/img/afton-negrea.png"
                        alt="Afton Negrea testimonial img"
                        title="Afton Negrea testimonial image"
                      />
                    </div>
                    <div className="pricing-testimonial-content">
                      <div className="app-sumo-rating">
                        <img
                          loading="lazy"
                          src="/assets/img/stars.svg"
                          alt="5 star icons in a row"
                          title="5 star icons in a row"
                          style={{ maxWidth: 96 }}
                        />
                      </div>
                      <p className="content">
                        As freelancers or as designers, as we are building
                        websites, we would add a certain amount of revisions or
                        iterations to the website. The more revisions or
                        iterations are made on a page, the costlier it could
                        become either for the client or for us in terms of time.
                        Ruttl has helped streamline our entire process &amp;
                        really changed the client experience for us.
                      </p>
                      <div className="author">Afton Negrea</div>
                      <div className="author-designation">
                        Digital Business Strategist at AftonNegrea.com
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  href="/customer-stories/"
                  className="button-transparent visible-mob">
                  Read our customer stories
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ transform: 'rotate(270deg)' }}>
                    <path
                      d="M7 1.16663V12.8333M12.8333 6.99996L7 12.8333L1.16666 6.99996"
                      stroke="#6552FF"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
                <div className="logo-box">
                  <div className="styled-trusted-by">
                    <div className="container">
                      <div className="flex justify-content-center align-items-center">
                        {brandLogosBugTrack.map(
                          ({ name, image, id, width, height }) => (
                            <div key={id} className="logo-image">
                              <img
                                loading="lazy"
                                src={image}
                                alt={name}
                                title={name}
                                width={width}
                                height={height}
                              />
                            </div>
                          ),
                        )}
                      </div>
                      <p className="center">
                        More than&nbsp;
                        {customerNumber}
                        &nbsp;creators and businesses across the world trust
                        ruttl
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <PricingTable
              pricingData={pricingTableData}
              views={views}
              currentTab={currentTab}
              toggleSignup={toggleSignup}
              toggleTab={toggleTab}
              proPlan={proPlan}
              standardPlan={standardPlan}
              teamPlan={teamPlan}
              isWebsiteFeedbackPage
              websitePlan={websitePlan}
              setWebsitePlan={setWebsitePlan}
              getProPlanValue={getProPlanValue}
              getStandardPlanValue={getStandardPlanValue}
              getTeamPlanValue={getTeamPlanValue}
              togglePurchaseModal={togglePurchaseModal}
              enterpriseData={enterpriseData}
              setCurrentTab={setCurrentTab}
            />
            {/* <section className="pricing-hero" style={{ paddingBottom: 0 }}>
              <div className="coupon-wrapper">
                <OfferCoupon
                  id="1"
                  saveAmount="Save $144 🔥"
                  discount="30% OFF"
                  desc="Offer applicable on Yearly Pro Plan"
                  code="RUTTL30"
                />
                <OfferCoupon
                  id="2"
                  saveAmount="Save $36 🔥"
                  discount="20% OFF"
                  desc="Offer applicable on Yearly Standard Plan"
                  code="HELLO20"
                />
                <OfferCoupon
                  id="3"
                  saveAmount="Save $27 🔥"
                  discount="15% OFF"
                  desc="Offer applicable on all monthly Plans"
                  code="RUTTL15"
                />
              </div>
            </section> */}
            <NewFaq data={faqDataCommon} />
            {/* <TestimonialsSlider greybg />
            <Feedback />
            <GetStarted toggleSignup={toggleSignup} /> */}
          </main>

          <div
            className={cn([
              'contact-us-modal',
              { show: typeof showPurchaseModal === 'number' },
            ])}
            id="purchase-modal">
            <div className="contact-us-container">
              <button
                type="button"
                className="button-unstyled close-button"
                disabled={fetchingLink}
                onClick={togglePurchaseModal(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="none"
                    stroke="#000000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="32"
                    d="M368 368L144 144M368 144L144 368"
                  />
                </svg>
              </button>
              {!showDemoCallModal ? (
                <>
                  <h3 style={{ marginTop: 0, marginBottom: 24, fontSize: 24 }}>
                    Contact Sales
                  </h3>
                  <form
                    autoComplete="off"
                    onSubmit={formProps.onSubmit}
                    id="contact-sales">
                    <div className="">
                      <div className="form-group">
                        <h4>
                          Name
                          {/* eslint-disable indent,react/jsx-indent */}
                          {formProps.getErrors('name').length
                            ? formProps.getErrors('name').map((e) => (
                                <span className="error" key={e.key}>
                                  {e.message}
                                </span>
                              ))
                            : null}
                          {/* eslint-enable indent,react/jsx-indent */}
                        </h4>
                        <label htmlFor="name">
                          <input
                            className="flex-grow-1 flex-basis-0"
                            type="text"
                            name="name"
                            placeholder="Enter your name"
                            onChange={handleChange}
                            value={formProps.values.name}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="">
                      <div className="form-group">
                        <h4 className="flex-grow-1">
                          Work Email
                          {/* eslint-disable indent,react/jsx-indent */}
                          {formProps.getErrors('email').length
                            ? formProps.getErrors('email').map((e) => (
                                <span className="error" key={e.key}>
                                  {e.message}
                                </span>
                              ))
                            : null}
                          {/* eslint-enable indent,react/jsx-indent */}
                        </h4>
                        <label htmlFor="email">
                          <input
                            type="text"
                            name="email"
                            placeholder="Enter your email"
                            onChange={handleChange}
                            value={formProps.values.email}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="">
                      <div className="form-group">
                        <div className="team-member">
                          <h4>Team Members</h4>
                          <div className="pricing-team-dropdown">
                            <button
                              type="button"
                              className="button-unstyled"
                              onClick={toggleTeamDropdown}>
                              <strong>{teamMembers}</strong>
                              &nbsp;members
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14px"
                                  height="100%"
                                  viewBox="0 0 512 512"
                                  fill="#fff"
                                  style={{ verticalAlign: 'middle' }}>
                                  <path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"></path>
                                </svg>
                              </span>
                            </button>
                            <div
                              className={`team-dropdown ${
                                teamDropdown ? 'show' : ''
                              }`}>
                              {[5, 10, 15, 20, 25].map((i) => (
                                <button
                                  key={i}
                                  data-size={i}
                                  type="button"
                                  className="button-unstyled"
                                  onClick={updateTeamMembers}>
                                  {teamMembers === i ? (
                                    <strong>{`${i} members`}</strong>
                                  ) : (
                                    `${i} members`
                                  )}
                                </button>
                              ))}
                            </div>
                            {teamDropdown && (
                              <button
                                type="button"
                                className="button-unstyled absolute-inset"
                                onClick={toggleTeamDropdown}
                                style={{
                                  position: 'fixed',
                                  cursor: 'default',
                                }}>
                                <span />
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <button
                        className="button button-green"
                        type="submit"
                        disabled={loading}>
                        {loading ? 'loading...' : 'Book Demo Call'}
                      </button>
                    </div>
                    {/* <button type="reset" onClick={formProps.reset}>Reset</button> */}
                  </form>
                </>
              ) : (
                <>
                  <h2>Thank you</h2>
                  <p>
                    Thanks for showing interest in our enterprise plan,
                    we&apos;d be glad to connect with you over a call to
                    understand your team requirement better. Please schedule a
                    call with us using the link below:
                  </p>
                  <div>
                    <a
                      className="button"
                      href="https://calendly.com/setup-demo-with-ruttl/30min"
                      target="_blank"
                      rel="noopener noreferrer">
                      Schedule a Demo
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

WebsiteFeedbackToolPricing.propTypes = {
  location: PropTypes.object,
};

export default WebsiteFeedbackToolPricing;
